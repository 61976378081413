import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import "./index.css";
import { ToastContainer } from "react-toastify";
import LoadingComponent from "../../components/loader/LoadingComponent";
import Header from "../../components/header/header";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import { BASE_URL, encryptData, S3_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY, failureAlert, IsAdmin, axiosApiInstance, videoUrl } from "../../helpers/helper";
import Footer from "../../components/footer/footer";
import iconcompetition from "../../assets/icons/iconcompetition.png";
import q from "../../assets/icons/quater.jpg";
import { Link } from 'react-router-dom';
import exploreMoreIcon from "../../assets/icons/exploreMoreIcon.png";
import Tabs from "../../components/AccordionTab";
import Accordion from "../../containers/Accordion";
import img1 from '../../assets/icons/image1.png';
import img2 from '../../assets/icons/image2.png';
import img3 from '../../assets/icons/imgab.png';
import ee from '../../assets/icons/ee.png';
import dei from '../../assets/icons/dei.png';
import rightarr from '../../assets/icons/rightarr.svg';
import { getAllQuartersInfo, addQuartersImages, updateQuarterData, addOccasionGamesQuartersData, getAllCalendarData, updateOccasionGamesInQuarter } from "../../redux/actions/calendarActions";
import { useSelector } from "react-redux";
const Calendar = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sectionRef = useRef(null);
  const sectionRefMobile = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };


  const handleDownloadDesktop = () => {
    setLoaded(false);
    if (sectionRef.current && imageLoaded) {
      html2canvas(sectionRef.current, {
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
        logging: true,
        scale: 2,
        x: 0,
        y: 0
      }).then((canvas) => {
        const imageUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'calendar.png';
        link.click();
      });
    }
    setLoaded(true);
  };

  const handleDownloadMobile = () => {
    setLoaded(false);
    if (sectionRefMobile.current && imageLoaded) {
      html2canvas(sectionRefMobile.current, {
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
        logging: true,
        scale: 2,
        x: 0,
        y: 0
      }).then((canvas) => {
        const imageUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'calendar.png';
        link.click();
      });
    }
    setLoaded(true);
  };
  const [focusedIdx, setFocusedIdx] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsPerPage = 12;
  const tabs1 = [
    { id: 0, label: "Play" },
    { id: 1, label: "Games" },
    { id: 2, label: "Live Events" }
  ];
  const playFaq = [
    { title: 'What is ExtraMile Play?', content: "ExtraMile Play caters to every kind of team—whether remote, offline, online, big, small, or somewhere in between, we've got the perfect adventure waiting for you!" },
    { title: 'How do I sign up for ExtraMile Play?', content: 'Signing up is as easy as a high-five! Just visit our website, choose your plan, and dive into a world where team spirit meets limitless fun.' },
    {
      title: "Can I play a game without subscribing to the platform?",
      content: "Yes, you can! Feel free to try out any game and play up to stage 1 to experience the fun and excitement firsthand before deciding to subscribe. It's the perfect way to get a feel for what ExtraMile Play has to offer!"
    },
    {
      title: " What subscription plans does ExtraMile Play offer?",
      content: "We offer flexible plans to suit every team's needs—whether you prefer monthly adventures or want to save with annual subscriptions, we've got options that fit your style of working!"
    }, {
      title: "Are there any discounts for annual subscriptions?",
      content: "Yes! Enjoy exclusive discounts when you opt for our annual subscription plans, making team-building with ExtraMile Play both rewarding and cost-effective."
    }
  ];

  const gamesFaq = [
    { title: 'What types of games are available on ExtraMile Play?', content: 'From brain-teasing puzzles to creative challenges and everything in between, ExtraMile Play offers a diverse range of games designed to spark joy and foster teamwork.' },
    { title: "Can we customise the games to fit our company's theme?", content: "Absolutely! Personalise your team's experience by customising games to reflect your company's culture, values, and goals—making each activity uniquely yours. The options are available here. " },
    { title: "How many players can participate in a game?", content: "Whether your team is small and intimate or large and dynamic, our games are crafted to accommodate all group sizes, ensuring everyone can join in on the fun! Know more about it here. " },
    { title: "Are the games suitable for remote teams?", content: "Yes! ExtraMile Play is perfect for remote teams, offering engaging activities that bridge distances and foster collaboration, no matter where your team members are located." },
  ];
  const liveEventsFaq = [
    { title: "Can I use the games to do LIVE events on Zoom, MS Teams etc?", content: "A 100% yes! Playing ExtraMile Play's games for LIVE events on Zoom, MS Teams, or any virtual platform is extremely easy. We believe in creating engaging experiences that bring teams together no matter where they are, ensuring every event is a hit!" },
    { title: "Are there facilitators available to help run the LIVE events?   ", content: "Yes! Our expert facilitators ensure your LIVE events run smoothly, guiding your team through engaging activities that enhance teamwork. When confirming the event, we share profiles of our experts for you to choose from, providing the perfect fit to help run your event." },
  ];
  let accordionItems;
  switch (focusedIdx) {
    case 1:
      accordionItems = gamesFaq;
      break;
    case 2:
      accordionItems = liveEventsFaq;
      break;
    default:
      accordionItems = playFaq;
  }
  const [loaded, setLoaded] = useState(true);
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };
  const allQuarterInfoData = useSelector((state) => state.allQuartersInfo);
  const calendarData = useSelector((state) => state.calendarData);
  const [calendarDataUploaded, SetAllCalendarData] = useState([]);
  useEffect(() => {
    dispatch(getAllQuartersInfo());
    dispatch(getAllCalendarData());
  }, [])

  useEffect(() => {
    if (calendarData && allQuarterInfoData) {
      const calendardata = calendarData?.allCalendarData?.data;
      SetAllCalendarData(calendardata)
    }
  }, [calendarData, allQuarterInfoData]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    return `${month} ${day}`;
  }

  const [openItem, setOpenItem] = useState(null);


  const toggleAccordion = (month, monthIndex) => {
    if (openItem && openItem.month === month && openItem.index === monthIndex) {
      setOpenItem(null);
    } else {
      setOpenItem({ month, index: monthIndex });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); 
}, []);
  return (
    <>
      <ToastContainer position="bottom-center" />
      <LoadingComponent loaded={loaded} />
      <Header
        profile
        {...props}
        signOut={signOut}
        isAdmin={isAdmin}
      />
      <section className="pt-[72px] flex flex-col items-center py-10 ">
        <div className="flex flex-col lg:flex-row gap-8 mb-8 items-center mt-12 lg:mt-24">
          <div class="circle-shadow hidden sm:block"></div>
          <div className="relative  p-4  w-64 mx-auto transition-transform transform hover:scale-105 hover:shadow-xl calendarMainCard">
            <img
              src={ee}
              alt="Top Right Image"
              className="absolute top-0 right-8 transform translate-x-1/2 -translate-y-1/2 w-12 h-12  object-cover"
            />
            <h2 className="text-center text-lg mt-0 ">Employee Engagement</h2>
          </div>
          <div class="circle-shadow-yellow hidden sm:block"></div>
          <div className="relative  p-4  rounded-md bg-white w-64 mx-auto transition-transform transform hover:scale-105 hover:shadow-xl Dei">
            <img
              src={dei}
              alt="Top Right Image"
              className="absolute top-0 right-8 transform translate-x-1/2 -translate-y-1/2 w-12 h-12  object-cover"
            />
            <h2 className="text-center text-lg mt-0 ">DEI</h2>
          </div>
        </div>



        <h1 className="text-xl sm:text-xl md:text-3xl lg:text-3xl font-bold text-center mb-4 mt-6 lg:mt-12">Employee Engagement <span className='highlight'>Calendar</span></h1>


        <p className="text-center  mb-6 calenderPara">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.
        </p>

        <div className="flex flex-col sm:flex-row justify-center ">
          <Link to={{
            pathname: "/",
            state: { fromCalendar: true }
          }} className="w-60 px-4 py-2 bg-white rounded-md hover:bg-red-500 hover:text-white calendarBorder mr-4 mb-4 sm:mb-0 flex items-center justify-center">

            Get In Touch
          </Link>



          <button
            onClick={handleDownloadDesktop}
            class="desktop-btn-down w-60 px-4 py-2 btn btn-primary rounded-md hover:bg-red-600"
          >
            Download Calendar
          </button>


          <button
            onClick={handleDownloadMobile}
            class="mobile-btn-down w-60 px-4 py-2 btn btn-primary rounded-md hover:bg-red-600"
          >
            Download Calendar
          </button>


        </div>


      </section>
      <section className="py-8 px-4 hidden lg:block gradient1" ref={sectionRef}>
        {calendarDataUploaded && calendarDataUploaded.map((quarter, quarterIndex) => (
          <>
            {quarterIndex === 0 && <div className="circle-shadow-pink"></div>}

            <div
              key={quarterIndex}
              className={`w-[90%] mx-auto overflow-hidden mb-8 OcasionContainerCard`}
            >
              <div className="grid grid-cols-6 gap-4 p-6">
                <div className="col-span-6 !pb-0 sm:col-span-1 flex flex-col items-center mb-4 sm:mb-0 relative rounded-xl overflow-visible">
                  <div className="w-full h-full relative">
                    <img
                      src={`${S3_BASE_URL}${encodeURIComponent(quarter.path)}`}
                      alt={quarter.quarter}
                      className="absolute  w-full h-full  rounded-xl mt-1  bg-gray-200"
                      onLoad={handleImageLoad}
                    />
                    <div className="relative z-10 p-4">
                      <h4 className="mb-0 text-black z-10 relative" style={{ fontSize: '50px', fontWeight: '900' }}>
                        {quarter.quarter}
                      </h4>
                      <p className="text-sm mb-1 text-black border-b-2 border-black w-max font-bold" style={{ paddingBottom: '8px' }}>
                        Coverage
                      </p>
                      <div className="w-full text-black z-10 relative bg-opacity-50">
                        <p className="text-sm mb-3 text-black pt-1 font-bold">{quarter.occasionCount} Occasions</p>
                        <p className="text-sm mb-3 text-black font-bold">{quarter.gameCount} Experiences</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-5 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {quarter.months.map((month, monthIndex) => (
                    <div
                      key={monthIndex}
                      className={`flex flex-col items-start p-2 sm:pr-6 border-b sm:border-b-0 ${monthIndex !== 2 ? 'sm:border-r-2' : ''}`}
                    >
                      <h4 className="calendarTitle mb-4">{month.month} </h4>
                      <ul className="list-none space-y-4 w-full scrollable-container">
                        {month.occasions.length === 0 ? (
                          <li className="text-gray-500">No Occasions Added</li>
                        ) : (
                          month.occasions.map((occasion, occasionIndex) => (
                            <li key={occasionIndex} className="flex items-center justify-between">
                              <div className="flex-grow">
                                <div className="CalendarEventName">{formatDate(occasion.date)}</div>
                                <div className="flex justify-between items-center">
                                  <span className="calendarSubTitle capitalize">{occasion.occasion}</span>
                                  <span className="ml-2 text-xl text-gray-500 mr-0">
                                    <Link
                                      className="block pl-6"
                                      to={{
                                        pathname: `/occasion-games/${occasion.id}`,
                                      }}
                                    >
                                      <img src={rightarr} alt="" className="w-3" />
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ))}
      </section>




      <section class="py-8 px-0 block sm:hidden gradient1" ref={sectionRefMobile}>


        {calendarDataUploaded && calendarDataUploaded.map((quarter, quarterIndex) => (
          <div key={quarterIndex} className="w-[90%] mx-auto overflow-hidden mb-8 OcasionContainerCard" style={{ height: 'auto' }}>
            <div className="grid grid-cols-6 gap-0 p-3">
              <div className="col-span-6 sm:col-span-1 flex flex-col items-center mb-0 sm:mb-0 relative rounded-xl overflow-scroll">
                <div className="w-full h-full relative" >

                  <img
                    src={`${S3_BASE_URL}${encodeURIComponent(quarter.path)}`}
                    alt={quarter.quarter}
                    className="absolute inset-0 w-full h-full object-cover rounded-xl bg-gray-200"
                  />
                  <div className="relative z-10 p-4">
                    <h4 className="mb-0 text-black z-10 relative" style={{ fontSize: '50px', fontWeight: '900' }}>
                      {quarter.quarter}
                    </h4>

                  </div>
                </div>
              </div>



              <div className="col-span-6 sm:col-span-5 grid grid-cols-1 sm:grid-cols-3 gap-4">
                {quarter.months.map((month, monthIndex) => (
                  <div
                    key={monthIndex}
                    className={`flex flex-col items-start p-4 sm:pr-6 sm:border-b-0 ${monthIndex !== 2 ? 'border-b-2' : ''}`}
                  >
                    <h4 className="calendarTitle mb-4">{month.month}</h4>
                    <ul className="list-none space-y-4 w-full  scrollable-container-mob">
                      {month.occasions.length === 0 ? (
                        <li className="text-gray-500">No Occasions Added</li>
                      ) : (
                        month.occasions.map((occasion, occasionIndex) => (
                          <li key={occasionIndex} className="flex items-center justify-between">
                            <div className="flex-grow">
                              <div className="CalendarEventName">{formatDate(occasion.date)}</div>
                              <div className="flex justify-between items-center">
                                <span className="calendarSubTitle capitalize">{occasion.occasion}</span>
                                <span className="ml-2 text-xl text-gray-500 mr-6">       <Link
                                  className="block pl-6"
                                  to={{
                                    pathname: `/occasion-games/${occasion.id}`,

                                  }}

                                ><img src={rightarr} alt="" className='w-3' />
                                </Link></span>
                              </div>
                            </div>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                ))}
              </div>

            </div>
          </div>
        ))}




      </section >
      <section className="section section6" >
        <div className="left-section desktopView">
          <div className="parent relative">

            <img className="image1 " src={img1} alt="Top Right Image" />
            <img className="image2" src={img3} alt="Bottom Left Image" />
          </div>
        </div>
        <div class="relative sm:hidden mt-[1rem] mb-[1rem]">
          <div class="relative z-20 top-0   right-[-20%]">
            <img src={img1} alt="Bottom Image" class="w-[80%] h-auto" />
          </div>
          <div class="absolute bottom-0 left-0 z-0">
            <img src={img2} alt="Top Image" class="w-[60%] h-auto" />
          </div>
        </div>
        <div className="right-section desktopView">
          <h2>
            Year Round Activities To Boot<br /><span className='highlight'>Employee Engagement</span>
          </h2>


          <p>No matter the distance, we have experiences to connect teams through non-transactional conversations</p>
          <div className="games-grid-items-container grid grid-cols-2 gap-4">
            <div className=" p-4 calendarSubCard">
              <h6 className="calendarSubTitle mb-2">What is Lorem Ipsum?</h6>
              <p className="calenderPara">Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</p>
            </div>

            <div className=" p-4 calendarSubCard">
              <h6 className="calendarSubTitle mb-2">What is Lorem Ipsum?</h6>
              <p className="calenderPara">Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</p>
            </div>
            <div className=" p-4 calendarSubCard">
              <h6 className="calendarSubTitle mb-2">What is Lorem Ipsum?</h6>
              <p className="calenderPara">Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</p>
            </div>
            <div className=" p-4 calendarSubCard">
              <h6 className="calendarSubTitle mb-2">What is Lorem Ipsum?</h6>
              <p className="calenderPara">Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</p>
            </div>

          </div>


        </div>
        <div className="right-section mobileView block sm:hidden ">
          <h2>
            Watch how teams <span className="text-highlight">collaborate and communicate</span> better across borders
          </h2>

          <p>No matter the distance, we have experiences to connect teams through non-transactional conversations</p>
          <div className="games-grid-items-container flex flex-col  space-y-4">
            <div className=" flex flex-col  w-full max-w-sm p-5 bg-white shadow-md rounded-lg">
              <span className="card-title text-xl font-semibold mb-2">Catering to every team size</span>
              <p className="card-description text-gray-500 text-center">
                Flexible for teams of any size, ensuring everyone is included in the fun.
              </p>
            </div>

            <div className=" flex flex-col  w-full max-w-sm p-5 bg-white shadow-md rounded-lg">
              <span className="card-title text-xl font-semibold mb-2">What is Lorem Ipsum?</span>
              <p className="card-description text-gray-500 text-center">
                Engage with your team in real time, fostering connections and collaboration.
              </p>
            </div>

            <div className=" flex flex-col  w-full max-w-sm p-5 bg-white shadow-md rounded-lg">
              <span className="card-title text-xl font-semibold mb-2">Supports online, offline and hybrid setup</span>
              <p className="card-description text-gray-500 text-center">
                Perfect for any event setup, whether online, offline, or hybrid.
              </p>
            </div>
            <div className=" flex flex-col  w-full max-w-sm p-5 bg-white shadow-md rounded-lg">
              <span className="card-title text-xl font-semibold mb-2">Supports online, offline and hybrid setup</span>
              <p className="card-description text-gray-500 text-center">
                Perfect for any event setup, whether online, offline, or hybrid.
              </p>
            </div>
            <div className=" flex flex-col  w-full max-w-sm p-5 bg-white shadow-md rounded-lg">
              <span className="card-title text-xl font-semibold mb-2">Customizable for all occasions</span>
              <p className="card-description text-gray-500 text-center">
                Tailor the experience to fit your team’s specific needs and preferences.
              </p>
            </div>
          </div>


        </div>
      </section>
      {/* <section className="section section6 pt-0 pb-0 " >
        <div className="left-section desktopView">
          <div className="parent relative">
       
            <img className="image1 " src={img1} alt="Top Right Image" />
            <img className="image2" src={img2} alt="Bottom Left Image" />
          </div>
        </div>
        <div class="relative sm:hidden mt-[1rem] mb-[1rem]">
          <div class="relative z-20 top-0   right-[-20%]">
            <img src={img1} alt="Bottom Image" class="w-[80%] h-auto" />
          </div>
          <div class="absolute bottom-0 left-0 z-0">
            <img src={img2} alt="Top Image" class="w-[60%] h-auto" />
          </div>
        </div>
        <div className="right-section desktopView">
          <h2>
            How To Approach Holiday <br /><span className='highlight'>Celebrations With Your Team</span>
          </h2>

          <p>No matter the distance, we have experiences to connect teams through non-transactional conversations</p>
          <div className="games-grid-items-container grid grid-cols-2 gap-4">
            <div className=" p-4 calendarSubCard">
              <h6 className="calendarSubTitle mb-2">What is Lorem Ipsum?</h6>
              <p className="calenderPara">Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</p>
            </div>

            <div className=" p-4 calendarSubCard">
              <h6 className="calendarSubTitle mb-2">What is Lorem Ipsum?</h6>
              <p className="calenderPara">Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</p>
            </div>
            <div className=" p-4 calendarSubCard">
              <h6 className="calendarSubTitle mb-2">What is Lorem Ipsum?</h6>
              <p className="calenderPara">Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</p>
            </div>
            <div className=" p-4 calendarSubCard">
              <h6 className="calendarSubTitle mb-2">What is Lorem Ipsum?</h6>
              <p className="calenderPara">Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</p>
            </div>
            <div className=" p-4 calendarSubCard">
              <h6 className="calendarSubTitle mb-2">What is Lorem Ipsum?</h6>
              <p className="calenderPara">Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</p>
            </div>
          </div>


        </div>
        <div className="right-section mobileView block sm:hidden">
          <h2>
            Watch how teams <span className="text-highlight">collaborate and communicate</span> better across borders
          </h2>

          <p>No matter the distance, we have experiences to connect teams through non-transactional conversations</p>
          <div className="games-grid-items-container flex flex-col  space-y-4">


            <div className=" flex flex-col  w-full max-w-sm p-5 bg-white shadow-md rounded-lg">
              <span className="card-title text-xl font-semibold mb-2">What is Lorem Ipsum?</span>
              <p className="card-description text-gray-500 text-center">
                Engage with your team in real time, fostering connections and collaboration.
              </p>
            </div>

            <div className=" flex flex-col  w-full max-w-sm p-5 bg-white shadow-md rounded-lg">
              <span className="card-title text-xl font-semibold mb-2">Supports online, offline and hybrid setup</span>
              <p className="card-description text-gray-500 text-center">
                Perfect for any event setup, whether online, offline, or hybrid.
              </p>
            </div>
            <div className=" flex flex-col  w-full max-w-sm p-5 bg-white shadow-md rounded-lg">
              <span className="card-title text-xl font-semibold mb-2">Supports online, offline and hybrid setup</span>
              <p className="card-description text-gray-500 text-center">
                Perfect for any event setup, whether online, offline, or hybrid.
              </p>
            </div>
            <div className=" flex flex-col  w-full max-w-sm p-5 bg-white shadow-md rounded-lg">
              <span className="card-title text-xl font-semibold mb-2">Customizable for all occasions</span>
              <p className="card-description text-gray-500 text-center">
                Tailor the experience to fit your team’s specific needs and preferences.
              </p>
            </div>
          </div>

        </div>
      </section> */}
      <section className="section10" >
        <div className="games-accordion-bookevent">
          <div className="title-wrapper">
            <div className="section-wrapper">
              <div className="title-wrapper1">
                <h2>
                  Frequently Asked<span className="highlight"> Questions </span>
                </h2>
                <p className="description">
                  Got questions? Check out our FAQs for all the info you may need or write to us if
                  we don’t answer them all.
                </p>
              </div>
              <div className="explore-more">
                <Link to={`/faq`} className="btn black">
                  <span style={{ marginRight: '10px' }}>  Got Questions</span>
                  <img src={exploreMoreIcon} alt="Icon" class="btn-icon" />
                </Link>
              </div>
            </div>
          </div>
          <div className="image-wrapper">
            <div className="button-group">
              <div className="buttongrp" style={{ marginBottom: '1rem', width: '100%' }}>
                <Tabs value={focusedIdx} onChange={setFocusedIdx} tabs={tabs1} />
              </div>
              <div className="accordion"><Accordion items={accordionItems} page="bookEvent" /></div>
            </div>
          </div>
        </div>
      </section>

      <div className="relative z-10">
        <Footer {...props} isAdmin={isAdmin} />
      </div>
    </>
  );

};

export default Calendar;