import React, { useEffect, useState, useRef } from "react";
import PageLayout from '../../components/pagelayout/pagelayout';
import ScheduledGames from '../../components/scheduledGames/scheduledGames';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import MyFavourites from "../../components/myFavourites/myFavourites";
import { sidebarContentScheduledByOthers, sideBarContentMyFavourites, sidebarContentScheduledByMe } from "../../helpers/sidebarContentMyGames";
import { logOut } from "../../redux/actions/userAction";
import Roles from '../../helpers/userTypes';
import Reports from "../reports/reports";
import PollDetailsTable from "../../components/pollDetailsTable/pollDetailsTable";
import DefaultGames from '../../components/defaultgames/DefaultGames';
import Header from "../../components/header/header";
import img1 from '../../assets/icons/image1.png';
import LoadingComponent from "../../components/loader/LoadingComponent";
import WebinarDescriptionModal from "../../components/modal/webinardescriptionmodal";
import WebinarPlayCard from "../../components/playCard/webinarPlayCard";
import arrowleft from '../../assets/images/paginationarrow.svg';
import lohri from '../../assets/images/LohriBanner2.png';
import search from '../../assets/images/search.svg';
import Playcardlarge from '../../components/playCard/playcardlarge';
import iconfilter from "../../assets/icons/iconfilter.png";
import { fetchOccasionGames } from "../../redux/actions/calendarActions";
import LikeShareModal from '../../components/modal/likesharemodal';
import PlayCard from "../../components/playCard/playCard_new";
import Footer from "../../components/footer/footer";
import CalendarGamesFilterModal from "../../components/modal/calendarGamesFilterModal";
import { gameAllCategory } from "../../redux/actions/gameDetailAction";
import { S3_BASE_URL, axiosApiInstance, BASE_URL, decryptData, encryptData, GetUserType, IsAdmin, REG_EX_URL_FORMAT, successAlert, failureAlert } from "../../helpers/helper";
const OccasionGames = (props) => {

  const allQuarterInfoData = useSelector((state) => state.occasionGames);
  const [openFilterModal, setOpenBookEventFilterModal] = useState(false);
  const [filtersCount, setFiltersSelectedCount] = useState('');
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [allGames, setAllGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const dispatch = useDispatch();
  const isAdmin = IsAdmin();
  const signOut = async () => {
    await dispatch(logOut());
    props.history.push("/");
  };
  const [page, setPage] = useState(1);
  const changePage = (newPage) => {

  }

  const debounceTimeoutRef = useRef(null);
  const [mobile, setMobileBanner] = useState(null);
  const [desktop, setDesktopBanner] = useState(null);
  const [openMobileHoverCard, setOpenMobileHoverCard] = useState(false);
  const [clearFilter, setClearFiltertrue] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [gameDetails, setGameDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("")
  const [webinarDetails, setWeinarDetails] = useState(null);
  const [openWebinarDescriptionModal, setOpenWebinarDescriptionModal] = useState(false);
  const { gameCategory } = useSelector((state) => state.gameAllCategory);
  const storedFormData = useSelector((state) => state.calendarfilterFormData);
  const clickFunction = (webinar) => {
    setWeinarDetails(webinar);
    setOpenWebinarDescriptionModal(true);
  }


  useEffect(() => {
    dispatch(gameAllCategory());
  }, [])
  useEffect(() => {
    if (searchTerm) {
      setLoaded(false);
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      debounceTimeoutRef.current = setTimeout(() => {
        setCurrentPage(1);
        dispatch(fetchOccasionGames(id, searchTerm));
      }, 1500);
    } else {
      setLoaded(false);
      dispatch(fetchOccasionGames(id, searchTerm));
    }

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchTerm, id, dispatch]);


  useEffect(() => {
    if (allQuarterInfoData) {
      const alldata = allQuarterInfoData?.occasionGames?.data?.games;
      const desktopBaner = allQuarterInfoData?.occasionGames?.data?.desktopBanner;
      const mobileBanner = allQuarterInfoData?.occasionGames?.data?.mobileBanner;

      setDesktopBanner(desktopBaner);
      setMobileBanner(mobileBanner);
      setAllGames(alldata);
      if (allQuarterInfoData.loading) {
        setLoaded(false);
      } else {
        setLoaded(true);
      }
    }
  }, [allQuarterInfoData]);
  const cardsPerPage = 12;


  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);


  const totalPages = Math.ceil(allGames?.length / cardsPerPage);


  useEffect(() => {
    setClearFiltertrue(false);
    if (currentPage && allGames && !openFilterModal) {
      const startIndex = (currentPage - 1) * cardsPerPage;
      const endIndex = currentPage * cardsPerPage;
      setCurrentData(allGames.slice(startIndex, endIndex));
    }
    if (currentPage && filteredGames && openFilterModal) {
      const startIndex = (currentPage - 1) * cardsPerPage;
      const endIndex = currentPage * cardsPerPage;
      setCurrentData(filteredGames.slice(startIndex, endIndex));
    }
  }, [currentPage, allGames, filteredGames]);


  const goToPage = (page) => {
    if (page < 1) {
      setCurrentPage(1);
    } else if (page > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(page);
    }
  };
  const getObjectKeysCountAndValues = (obj) => {

    if (!obj || Object.keys(obj).length === 0) {
      return null;
    }

    const result = Object.entries(obj).reduce((acc, [key, value]) => {
      if (key === 'id') {
        return acc;
      }
      if (value !== undefined && value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
        acc.push({
          key,
          value: Array.isArray(value) ? value.length : value,
        });
      }
      return acc;
    }, []);


    return {
      count: result.length,
      keysWithValues: result,
    };
  };

  useEffect(() => {
    if (storedFormData) {
      if (storedFormData.formData) {
        const result = getObjectKeysCountAndValues(storedFormData.formData);

        setFiltersSelectedCount(result.count);
      }
    }
  }, [storedFormData]);

  const componentStyleValue = {
    mobile: "md:!w-[190px] md:!h-[280px] sm:!w-[155px] sm:!h-[238px] lg:!w-[200px] lg:!h-[300px] !w-[155px] !h-[238px]",
  }

  const openFilter = () => {
    setOpenBookEventFilterModal(true);
  };


  const handleGamesChange = (data) => {
  setFilteredGames(data?.games);
  };

  const handleClearFilters = async (clear) => {
    if (clear) {
      setClearFiltertrue(true);
      dispatch(fetchOccasionGames(id, ''));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0); 
}, []);
  return (
    <div >
      {
        openFilterModal &&
        <CalendarGamesFilterModal modalid={"filter-modal"} toggle={openFilterModal} experiences={gameCategory?.data} onCategoriesChange={handleGamesChange} setOpenBookEventFilterModal={setOpenBookEventFilterModal} onClearFilters={handleClearFilters} />
      }
      {
        openShareModal && (
          <LikeShareModal
            toggle={openShareModal}
            setOpenShareModal={setOpenShareModal}
            shareLink={shareLink}
          />
        )
      }
      <Header profile {...props} signOut={signOut} {...props} isAdmin={isAdmin} />
      <div className=" w-full h-auto  p-0 mt-[73px] ">
        <div className="w-full">
          {!loaded ? (
            <div className="bg-gray-200 animate-pulse w-full skeleton-animation" style={{ height: 'calc(70vh)' }}></div>
          ) : (
            <div className="bg-cover bg-center w-full">

              <img
                className="md:hidden w-full"
                src={S3_BASE_URL + mobile}
                alt="Mobile background"
                style={{
                  height: 'calc(100vh - 72px)',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
              <img
                className="hidden md:block w-full"
                src={S3_BASE_URL + desktop}
                alt="Desktop background"
                style={{
                  height: 'calc(100vh - 72px)',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
          

            </div>

          )}
        </div>
        <div className="w-[90%] p-0 flex justify-between items-center bg-white mt-12 mb-12 mx-auto">

          <div className="mb-0 lg:mb-0 lg:mr-4 lg:ml-1">
            <button onClick={() => openFilter()} className="filter-button-with-badge mr-3">
              <img src={iconfilter} alt="Icon" className="mr-2 w-4 h-4 " />
              <span>Filters</span>
              {filtersCount > 0 && <span className="badge">{clearFilter ? '' : filtersCount}</span>}
            </button>
          </div>
          <div class=" input-icon shadow mr-0">
            <img src={search} alt="search" />
            <input type="text" name="search" placeholder="Search a game"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
        </div>
        <LoadingComponent loaded={loaded} />
        {
          !loaded ? (
         
            <div className="flex justify-center items-center mr-[20px] mb-5">
              {Array.from({ length: 6 }).map((_, indx) => (
                <div
                  key={indx}
                  className="z-[80] flex justify-center items-center mr-[20px] mb-5 rounded-[10px] md:w-[220px] lg:w-[220px] md:h-[320px] lg:h-[320px]"
                >
                  <div className="flex bg-slate-100 rounded-[10px] w-[200px] h-[300px] skeleton-animation"></div>
                </div>
              ))}
            </div>
          ) : (
            currentData && currentData.length > 0 ? (
              <div
                className="gap-y-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 w-[90%] h-auto mx-auto"
                style={{
                  columnGap: 'calc((100% - (5 * 190px)) / 8)' 
                }}
              >
                {currentData.map((game, index) => (
                  game.isWebinar ? (
                    <WebinarPlayCard
                      key={JSON.stringify(game)}
                      gameDetail={game}
                      title={game.title}
                      srcImage={[game.mobileCoverMedia]}
                      setOpenShareModal={setOpenShareModal}
                      setShareLink={setShareLink}
                      isFromWebinar={true}
                      webinarClickFunction={() => clickFunction(game)}
                      setGameDetails={setGameDetails}
                      setOpenMobileHoverCard={setOpenMobileHoverCard}
                    />
                  ) : (
                    <PlayCard
                      key={JSON.stringify(game)}
                      gameDetail={game}
                      title={game.title}
                      srcImage={game.coverMedia}
                      setOpenShareModal={setOpenShareModal}
                      setShareLink={setShareLink}
                      // setHover={setHover}
                      // setHoverSlogan={() => setHoverSlogan(slogan.id)}
                      setGameDetails={setGameDetails}
                      setOpenMobileHoverCard={setOpenMobileHoverCard}
                      componentStyle={componentStyleValue}
                    />
              
                  )
                ))}
              </div>
            ) : (
              <div className="no-game-wrapper !h-40">
                <div>
                  <h3 className="no-game">
                    The game you are searching for is currently not available.
                  </h3>
                  <span
                    className="no-game-span"
                    onClick={() => props.history.push("/")}
                  >
                    Go to Homepage
                  </span>
                </div>
              </div>
            )
          )
        }




        {currentData && currentData?.length > 0 &&
          <div className="pagination-wrapper">

            <button
              className={`pagination-left ${currentPage > 1 ? "enable" : ""} flex justify-center items-center`}
              onClick={() => goToPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src={arrowleft} alt="arrow left" />
            </button>


            <div className="pagination-number">
              <h5>{currentPage}</h5>
              <span>of {totalPages} pages</span>
            </div>


            <button
              className={`pagination-right ${currentPage < totalPages ? "enable" : ""} flex justify-center items-center`}
              onClick={() => goToPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src={arrowleft} alt="arrow right" />
            </button>
          </div>
        }

      </div>
      <Footer {...props} isAdmin={isAdmin} />
    </div>
  );

}

export default OccasionGames
