import React, { useState } from "react";
import PageLayout from "../../components/pagelayout/pagelayout";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { logOut } from "../../redux/actions/userAction";
import "./offlinequote.css";
import DataTable from "react-data-table-component";
import search from "../../assets/images/search.svg";
// import PaymentDetailsModal from "../modal/paymentDetailsModal";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { useEffect } from "react";
import { getAllPaymentForAdmin } from "../../redux/actions/paymentApiActions";
import useDebouncedSearch from "../../helpers/debounce";
import { verifyOfflinePayment } from "../../redux/actions/paymentApiActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { failureAlert, IsAdmin, successAlert } from "../../helpers/helper";
import Select from "react-select";
import { SYMBOLS } from "../../constants";
import { getAllOfflineQuotes } from "../../redux/actions/offlineQuoteApiActions";
import { getAllQuartersInfo, addQuartersImages, updateQuarterData, addOccasionGamesQuartersData, getAllCalendarData, updateOccasionGamesInQuarter } from "../../redux/actions/calendarActions";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from '../../redux/actions/commonActions';
import {
  S3_BASE_URL,
  BASE_URL,
  GOOGLE_RECAPTCHA_SITE_KEY,
  axiosApiInstance,
  encryptData,
  decryptData
} from "../../helpers/helper";
import { cos } from "synonyms/dictionary";
import { getAllGames } from "../../redux/actions/homepageActions";

const CalendarAdmin = (props) => {
  const [loaded, setLoaded] = useState(true);
  const AllGames = useSelector(state => state.allGames);
  const { allGames } = AllGames;
  const [allQuartersInfo, setAllQuartersInfo] = useState([]);
  const [addedCalendarData, setAllCalendarData] = useState(null);
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };
  const allQuarterInfoData = useSelector((state) => state.allQuartersInfo);
  const calendarData = useSelector((state) => state.calendarData);
  const submitQuarterImages = async (e) => {
    e.preventDefault();
    if (validateForm()) {
       const newRows = [...rows];
       const newErrors = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
             if (row.file) {
              const data = new FormData();
          data.append("quarter-thumbnail", row.file);
          const response = await dispatch(uploadFile(data));
          const fileUploadedPath = response?.data?.data?.path;
          if (fileUploadedPath) {
            newRows[i].path = fileUploadedPath;
          } else {
            newErrors[i] = 'File upload failed';
          }
        }
      }
  
      const response = await dispatch(addQuartersImages({ allQuartersData: newRows }));
      if (response === 200) {
        successAlert("Data Added Successfully!");
        dispatch(getAllQuartersInfo());
      }
      else
        failureAlert("Something went wrong!");
    } else {
      console.log('There are errors in the form');
    }
  };
  const [errors, setErrors] = useState([]);
  const validateForm = () => {
    const newErrors = [];
    rows.forEach((row, index) => {
      if (!row.file) {
        newErrors[index] = 'Please upload file';
      }
    });
    setErrors(newErrors);
    return newErrors.length === 0;
  };


  useEffect(() => {
    if (allQuarterInfoData) {
      const alldata = allQuarterInfoData?.allQuartersInfo?.data;
      const sortedArray = allQuarterInfoData?.allQuartersInfo?.data.sort((a, b) => a.name - b.name);
      setAllQuartersInfo(sortedArray);
      if (allQuarterInfoData.loading) {
        setLoaded(false);
      } else {
        setLoaded(true);
      }
    }
  }, [allQuarterInfoData]);

  useEffect(() => {
    if (calendarData) {
      setAllCalendarData(calendarData?.allCalendarData?.data);
      setModifiedData(calendarData?.allCalendarData?.data)
      if (calendarData.loading) {
        setLoaded(false);
      } else {
        setLoaded(true);
      }
    }
  }, [calendarData]);




  
  const quarterMonths = {
    q1: ['January', 'February', 'March'],
    q2: ['April', 'May', 'June'],
    q3: ['July', 'August', 'September'],
    q4: ['October', 'November', 'December'],
  };
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const [months, setMonths] = useState([]);

  const handleQuarterChange = (event) => {
    setSelectedMonth("");
    setSelectedQuarter(event.target.value);
    const selectedQuarter = allQuartersInfo.find(quarter => quarter.id === event.target.value);
    const selectedQuarterName = selectedQuarter ? selectedQuarter.name : null;
     const Quarter = "q" + selectedQuarterName;
    setMonths(quarterMonths[Quarter] || []);
  };


  const [rows, setRows] = useState([
    { quarter: '1', image: '', file: null, path: '' },
    { quarter: '2', image: '', file: null, path: '' },
    { quarter: '3', image: '', file: null, path: '' },
    { quarter: '4', image: '', file: null, path: '' }
  ]);
  const [activeTab, setActiveTab] = useState(1); 
  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };


  const handleFileChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      const newRows = [...rows];
      newRows[index].image = file;
      newRows[index].file = file;
      setRows(newRows);
    }
  };


 
  const handleFileChangeAfterUpload = async (row, e) => {
     const file = e.target.files[0];
    if (file) {
      setLoaded(false);
      const quarterImage = [row.path];
      await axiosApiInstance.post(`${BASE_URL}/api/file/deleteBanners`, quarterImage);
      const data = new FormData();
      data.append("quarter-thumbnail", file);
      const response = await dispatch(uploadFile(data));
      const fileUploadedPath = response?.data?.data?.path;
      const responsecode = await dispatch(updateQuarterData(fileUploadedPath, row.id));
      dispatch(getAllQuartersInfo());
      setLoaded(true);
    }
  };


  useEffect(() => {
    dispatch(getAllQuartersInfo());
    dispatch(getAllGames());
  }, [])
  const [gameOptions, setGameOptions] = useState([]);
  const [gameOptions1, setGameOptions1] = useState([]);
   const [selectedMonth, setSelectedMonth] = useState("");
  const [occasionName, setOccasionName] = useState("");
  const [occasionDate, setOccasionDate] = useState("");
  const [selectedGames, setSelectedGames] = useState([]);
  const [mobileThumbnail, setMobileThumbnail] = useState(null);
  const [desktopThumbnail, setDesktopThumbnail] = useState(null);
  const [mobileThumbnailPreview, setMobileThumbnailPreview] = useState(null);
  const [desktopThumbnailPreview, setDesktopThumbnailPreview] = useState(null);
  useEffect(() => {
    if (allGames && allGames.data) {
      const options = allGames.data.map(game => (
        { id: game.id, label: game.title, value: game.title }
      ))
      setGameOptions(options);
    }
  }, [allGames]);

  useEffect(() => {
    if (allGames && allGames.data) {
      const options = allGames.data.map(game => (
        { value: game.id, label: game.title }
      ))
      setGameOptions1(options);
    }
  }, [allGames]);
  useEffect(() => {
    if (activeTab === 3) {
      dispatch(getAllCalendarData());
    }
  }, [activeTab])

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleGameSelect = (selectedOptions) => {
    setSelectedGames(selectedOptions);
  };
  const [errorsOcasionForm, setErrorsOcasionForm] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorsOcasionForm({});
    let validationErrors = {};
    if (!selectedQuarter) validationErrors.quarter = "Quarter is required.";
    if (!selectedMonth) validationErrors.month = "Month is required.";
    if (!occasionName) validationErrors.occasionName = "Occasion name is required.";
    if (!occasionDate) validationErrors.occasionDate = "Occasion date is required.";
    if (!mobileThumbnail) validationErrors.mobileThumbnail = "Mobile thumbnail is required.";
    if (!desktopThumbnail) validationErrors.desktopThumbnail = "Desktop thumbnail is required.";
     if (Object.keys(validationErrors).length > 0) {
      setErrorsOcasionForm(validationErrors);
      return;
    }

    setLoaded(false);
   
    const desktop = new FormData();
    const mobile = new FormData();
    desktop.append("quarter-thumbnail", desktopThumbnail);
    mobile.append("quarter-thumbnail", mobileThumbnail);
    const desktopPath = await dispatch(uploadFile(desktop));
    const mobilePath = await dispatch(uploadFile(mobile));
    const result1 = desktopPath?.data?.data?.path;
    const result2 = mobilePath?.data?.data?.path;

    const occasionData = {
      occasionName: occasionName,
      month: selectedMonth,
      occasionDate: occasionDate,
      quarterId: selectedQuarter,
      games: selectedGames,
      desktopBanner: result1,
      mobileBanner: result2
    };


    try {
      await dispatch(addOccasionGamesQuartersData(occasionData));
      successAlert('Data Added Succesfully');
      setSelectedQuarter('');
      setSelectedMonth('');
      setOccasionName('');
      setOccasionDate('');
      setSelectedGames([]);
      setErrors({});
      setDesktopThumbnailPreview(null);
      setMobileThumbnailPreview(null);
      document.getElementById("mobile-thumbnail").value = ""; 
      document.getElementById("desktop-thumbnail").value = ""; 
      setLoaded(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      failureAlert('Something went wrong');
      setLoaded(true);
    }
  };

  const [modifiedData, setModifiedData] = useState('');
  const [openTitle, setOpenTitle] = useState('');
  
  const handleInputChange_1 = (e, quarterIndex, monthIndex, occasionIndex, field) => {
    const updatedData = [...modifiedData];
    updatedData[quarterIndex].months[monthIndex].occasions[occasionIndex][field] = e.target.value;
    setModifiedData(updatedData);
  };
  const handleFileChange1 = async (e, quarterIndex, monthIndex, occasionIndex, existingThumbnail, field) => {
    const file = e.target.files[0];
    if (!file) return;
  
    try {
      if (existingThumbnail) {
        setLoaded(false);
        const thumbnail = [existingThumbnail];
        await axiosApiInstance.post(`${BASE_URL}/api/file/deleteBanners`, thumbnail);
      }
      const data = new FormData();
      data.append("quarter-thumbnail", file);
      const response = await dispatch(uploadFile(data));
      const fileUploadedPath = response?.data?.data?.path;
  
      if (!fileUploadedPath) throw new Error('File upload failed');
  
      const updatedData = [...modifiedData];
      updatedData[quarterIndex].months[monthIndex].occasions[occasionIndex][field] = fileUploadedPath;
      setModifiedData(updatedData);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      console.error("Error handling file change:", error);
    }
  };
  
  const handleGameSelect_1 = (selectedGames, quarterIndex, monthIndex, occasionIndex) => {
     const updatedData = [...modifiedData];

    updatedData[quarterIndex].months[monthIndex].occasions[occasionIndex].games = selectedGames.map(game => ({
      id: game.value, 
      title: game.label  
    }));

    setModifiedData(updatedData);
  };



  
  const handleUpdate = async (quarterIndex, monthIndex, occasionIndex) => {
    setLoaded(false);
    const updatedData = [...modifiedData];
    const occasionData = modifiedData[quarterIndex].months[monthIndex].occasions[occasionIndex];
    await dispatch(updateOccasionGamesInQuarter(occasionData));
    await dispatch(getAllCalendarData());
    successAlert('Data Updated Successfully');
    setLoaded(true);
  };

  const deleteOccasion = async (occasionId) => {
    setLoaded(false);
    await axiosApiInstance.delete(`${BASE_URL}/api/Calendar/delete/${occasionId}`);
    await dispatch(getAllCalendarData());
    successAlert('Deleted Successfully');
    setLoaded(true);

  };

  const handleMobileThumbnailChange = (e) => {
    const file = e.target.files[0];
    setMobileThumbnail(file);
    if (file) {
      setMobileThumbnailPreview(URL.createObjectURL(file));
    }
  };

  const handleDesktopThumbnailChange = (e) => {
    const file = e.target.files[0];
    setDesktopThumbnail(file);
    if (file) {
      setDesktopThumbnailPreview(URL.createObjectURL(file));
    }
  };
  const [openIndex, setOpenIndex] = useState(null);

 
  const handleAccordionToggle = (index, title) => {
     setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    setOpenTitle(title);
  };
  return (
    <div className="admin-homepage">
      <PageLayout
        sidebartitle=""
        active={"Calendar"}
        category
        sideBarContents={sidebarContentAdmin}
        profile
        {...props}
        signOut={signOut}
        {...props}
        isAdmin={isAdmin}
      >
        <LoadingComponent loaded={loaded} />
        <ToastContainer position="bottom-center" />
        <div className="container-payment">
          <div className="payment">
            <h1 className="title text-left">Calendar Details</h1>
          </div>
        </div>

        <div className="container !pt-3">
          <div className="tabs mb-5">
            <button
              className={`btn btn-default mr-3 ${activeTab === 1 ? "active btn-primary" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              Upload Thumbnail
            </button>
            <button
              className={`btn btn-default mr-3${activeTab === 2 ? "active btn-primary" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              Add Occasions Data
            </button>
            <button
              className={`btn btn-default ${activeTab === 3 ? "active btn-primary" : ""}`}
              onClick={() => setActiveTab(3)}
            >
              Data Uploaded
            </button>
          </div>


          {activeTab === 1 && (
            allQuartersInfo && allQuartersInfo.length === 0 ? (
              <form onSubmit={submitQuarterImages} className="mx-auto w-full border-2 p-5 rounded-lg">
                {rows.map((row, index) => (
                  <div key={index} className="grid grid-cols-1 md:grid-cols-3 md:gap-6 mb-5 border-b-2">
                    <div className="relative z-0 w-full mb-5 group">
                      <label htmlFor={`name-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Quarter {index + 1}
                      </label>
                    </div>

                   
                    <div className="relative z-0 w-full mb-2 group flex flex-col items-center justify-center">
                      <label htmlFor={`image-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Quarter Thumbnail
                      </label>
                      {row.image ? (
                        <img
                          src={URL.createObjectURL(row.image)} 
                          alt="Preview"
                          className="w-24 h-24 object-cover mb-3"
                        />
                      ) : (
                        <div className="w-24 h-24 bg-gray-200 mb-3 flex items-center justify-center">
                          <span>No Image</span>
                        </div>
                      )}
                    </div>

                   
                    <div className="relative z-0 w-full mb-5 group">
                      <label htmlFor={`file-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Upload New Thumbnail
                      </label>
                      <input
                        type="file"
                        id={`file-${index}`}
                        onChange={(e) => handleFileChange(index, e)}
                        className=" text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        accept="image/*"
                      />
                      {errors[index] && <p className="text-red-500 text-sm mt-2">{errors[index]}</p>}
                    </div>
                  </div>
                ))}

                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </form>
            ) : (
              <div className="!bg-white p-3 rounded-md">
                {allQuartersInfo && allQuartersInfo.map((row, index) => (
                  <div key={index} className="grid grid-cols-1 md:grid-cols-3 md:gap-6 mb-5 border-b-2">
                    <div className="relative z-0 w-full mb-5 group">
                      <label htmlFor={`name-${index}`} className="block mb-2 text-sm font-medium text-gray-900 font-bold">
                        Quarter {row.name}
                      </label>
                    </div>


                    <div className="relative z-0 w-full mb-2 group flex flex-col items-center justify-center">
                      <label htmlFor={`image-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Existing Thumbnail
                      </label>

                      <img
                        src={S3_BASE_URL + row.path}
                        alt="Preview"
                        className="w-24 h-24 object-cover mb-3"
                      />

                    </div>


                    <div className="relative z-0 w-full mb-5 group">
                      <label htmlFor={`file-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Upload New Thumbnail
                      </label>
                      <input
                        type="file"
                        id={`file-${index}`}
                        onChange={(e) => handleFileChangeAfterUpload(row, e)}
                        className=" text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        accept="image/*"
                      />

                    </div>
                  </div>
                ))}
              </div>
            )
          )}



          {activeTab === 2 && (
            <form className="mx-auto w-full p-5 rounded-lg bg-white" onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="w-full mb-5 group">
                  <label>Quarter</label>
                  <select
                    value={selectedQuarter}
                    onChange={handleQuarterChange}
                    className="h-auto text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option value="">Select Quarter</option>
                    {allQuartersInfo && allQuartersInfo.map((quarter) => (
                      <option key={quarter.id} value={quarter.id}>
                        Quarter {quarter.name}
                      </option>
                    ))}
                  </select>
                  {errorsOcasionForm.quarter && <p className="text-red-500 text-xs">{errorsOcasionForm.quarter}</p>}
                </div>
                <div className="w-full mb-5 group">
                  <label>Select Month</label>
                  <select
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    className="h-auto text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option value="">Select month</option>
                    {months.map((month, index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  {errorsOcasionForm.month && <p className="text-red-500 text-xs">{errorsOcasionForm.month}</p>}
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">

                <div className="w-full mb-5 group">
                  <label htmlFor="occasion-name" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Name
                  </label>
                  <input
                    type="text"
                    id="occasion-name"
                    className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-2"
                    placeholder="Occasion Name"
                    value={occasionName}
                    onChange={(e) => setOccasionName(e.target.value)}

                  />
                  {errorsOcasionForm.occasionName && <p className="text-red-500 text-xs">{errorsOcasionForm.occasionName}</p>}
                </div>


                <div className="relative z-0 w-full mb-5 group">
                  <label htmlFor="occasion-date-input" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Date
                  </label>
                  <input
                    type="date"
                    id="occasion-date-input"
                    className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-2"
                    value={occasionDate}
                    onChange={(e) => setOccasionDate(e.target.value)}

                  />
                  {errorsOcasionForm.occasionDate && <p className="text-red-500 text-xs">{errorsOcasionForm.occasionDate}</p>}
                </div>
              </div>


              <div className="relative z-10 w-full mb-5 group">
                <label htmlFor="select-games" className="block mb-2 text-sm font-medium text-gray-900">
                  Select Games
                </label>
                <Select
                  id="select-games"
                  classNamePrefix="react-select"
                  options={gameOptions}
                  menuPlacement="auto"
                  isMulti
                  placeholder="Select Games"
                  onChange={handleGameSelect}
                  value={selectedGames}
                />
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">

                <div className="w-full mb-5 group">
                  <label htmlFor="occasion-name" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Thumbnail (Mobile)
                  </label>
                  <input
                    type="file"
                    id="mobile-thumbnail"
                    className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-2"
                    onChange={handleMobileThumbnailChange}
                  />
                  {errorsOcasionForm.mobileThumbnail && (
                    <p className="text-red-500 text-xs">{errorsOcasionForm.mobileThumbnail}</p>
                  )}
                </div>


                <div className="relative z-0 w-full mb-5 group">
                  <label htmlFor="occasion-date-input" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Thumbnail Preview (Mobile)
                  </label>
                  {mobileThumbnailPreview && (
                    <div className="mt-2">
                      <img src={mobileThumbnailPreview} alt="Mobile Thumbnail Preview" className="w-32 h-32 object-cover" />
                    </div>
                  )}
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">

                <div className="w-full mb-5 group">
                  <label htmlFor="occasion-name" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Thumbnail (Desktop)
                  </label>
                  <input
                    type="file"
                    id="desktop-thumbnail"
                    className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-2"
                    onChange={handleDesktopThumbnailChange}
                  />
                  {errorsOcasionForm.desktopThumbnail && (
                    <p className="text-red-500 text-xs">{errorsOcasionForm.desktopThumbnail}</p>
                  )}

                </div>


                <div className="relative z-0 w-full mb-5 group">
                  <label htmlFor="occasion-date-input" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Thumbnail Preview (Desktop)
                  </label>
                  {desktopThumbnailPreview && (
                    <div className="mt-2">
                      <img src={desktopThumbnailPreview} alt="Desktop Thumbnail Preview" className="w-32 h-32 object-cover" />
                    </div>
                  )}

                </div>
              </div>



              <button
                type="submit"
                className="text-white btn btn-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                disabled={Object.keys(errors).length > 0}
              >
                Submit
              </button>
            </form>

          )}

          {activeTab === 3 && (
            <div className="container mx-auto !pt-3 bg-white p-0">
              {modifiedData && modifiedData.map((quarterData, quarterIndex) => (
                <div key={quarterIndex} className="mb-6">
                  <h6 className="text-lg font-bold p-4">{quarterData.quarter}</h6>

                  <div className="ml-4 bg-white">
                    <table className="min-w-full table-auto border-collapse border-2 table-fixed">
                      <thead>
                        <tr>
                          <th className="border p-2 w-1/3">Month</th>
                          <th className="border p-2 w-2/3">Occasion Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quarterData.months.map((monthData, monthIndex) => (
                          <tr key={monthIndex} className="border-b-2 ">
                            <td className="p-2 align-baseline font-bold w-1/6 border">{monthData.month}</td> 
                            <td className="p-2 w-5/6 border" colSpan={4}> 
                              {monthData.occasions.length === 0 ? (
                                <p className="text-gray-500">No data added</p>
                              ) : (
                                <div className="w-full">
                                  {monthData.occasions.map((occasionData, occasionIndex) => (
                                    <div
                                      key={occasionIndex}
                                      className={`border rounded`}
                                    >
                                      <div className="flex items-center justify-between p-2 ">
                                        <button
                                          onClick={() => handleAccordionToggle(occasionIndex, monthData.month)}
                                          className="flex items-center justify-between w-full font-medium rtl:text-right text-gray-500 rounded mb-2"
                                          data-accordion-target={`#accordion-collapse-body-${occasionIndex}`}
                                          aria-expanded={openIndex === occasionIndex && openTitle === monthData.month ? "true" : "false"}
                                          aria-controls={`accordion-collapse-body-${occasionIndex}`}
                                        >
                                          <span className="font-bold capitalize text-black">{occasionData.occasion} </span>
                                          <svg
                                            data-accordion-icon
                                            className={`w-3 h-3 ${openIndex === occasionIndex && openTitle === monthData.month ? 'rotate-180' : ''} shrink-0`}
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 10 6"
                                          >
                                            <path
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M9 5 5 1 1 5"
                                            />
                                          </svg>
                                        </button>
                                      </div>

                                      <div
                                        id={`accordion-collapse-body-${occasionIndex}`}
                                        className={openIndex === occasionIndex && openTitle === monthData.month ? "" : "hidden"}
                                        aria-labelledby={`accordion-collapse-heading-${occasionIndex}`}
                                      >
                                        <div className="p-5">
                                          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                                            <div>
                                              <div>
                                                <label className="block text-sm font-medium text-gray-700">Occasion Name</label>
                                                <input
                                                  type="text"
                                                  value={occasionData.occasion}
                                                  onChange={(e) => handleInputChange_1(e, quarterIndex, monthIndex, occasionIndex, 'occasion')}
                                                  className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                  placeholder="Enter value"
                                                />
                                              </div>
                                              <div>
                                                <label className="block text-sm font-medium text-gray-700 mt-3">Occasion Date</label>
                                                <input
                                                  type="date"
                                                  value={occasionData.date}
                                                  onChange={(e) => handleInputChange_1(e, quarterIndex, monthIndex, occasionIndex, 'date')}
                                                  className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                  placeholder="Enter value"
                                                />
                                              </div>
                                            </div>

                                            <div>
                                              <div>
                                                <label className="block text-sm font-medium text-gray-700">Mobile Thumbnail</label>
                                                <input
                                                  type="file"
                                                  className="mt-1 block w-full px-4 py-2  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                  placeholder="Enter value"
                                                  onChange={(e) => handleFileChange1(e, quarterIndex, monthIndex, occasionIndex,occasionData.mobileBanner, 'mobileBanner')}
                                                
                                                />
                                              </div>
                                              <div>
                                                <label className="block text-sm font-medium text-gray-700 mt-3">Mobile Preview</label>
                                                <img
                                                  src={S3_BASE_URL + occasionData.mobileBanner}
                                                  alt="Preview"
                                                  className="w-full h-10 object-cover mb-3"
                                                />
                                              </div>
                                            </div>

                                            <div>
                                              <div>
                                                <label className="block text-sm font-medium text-gray-700">Desktop Thumbnail</label>
                                                <input
                                                  type="file"
                                                  className="mt-1 block w-full px-4 py-2 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                  placeholder="Enter value"
                                                  onChange={(e) => handleFileChange1(e, quarterIndex, monthIndex, occasionIndex,occasionData.desktopBanner, 'desktopBanner')}
                                                />
                                              </div>
                                              <div>
                                                <label className="block text-sm font-medium text-gray-700 mt-3">Desktop Preview</label>
                                                <img
                                                  src={S3_BASE_URL + occasionData.desktopBanner}
                                                  alt="Preview"
                                                  className="w-full h-10 object-cover mb-3"
                                                />
                                              </div>
                                            </div>


                                            <div className="col-span-4">
                                              <div>
                                                <label className="block text-sm font-medium text-gray-700">Select Games</label>
                                                <Select
                                                  classNamePrefix="react-select"
                                                  className="w-full"
                                                  options={gameOptions1}
                                                  isMulti
                                                  placeholder="Select Games"
                                                  value={occasionData.games.map(game => ({ value: game.id, label: game.title }))}
                                                  onChange={(selectedGames) => handleGameSelect_1(selectedGames, quarterIndex, monthIndex, occasionIndex)}
                                                />
                                              </div>

                                              <div className="flex space-x-4 mt-4">
                                                <button className="btn bg-red-200 text-xs mr-2 rounded-sm" onClick={() => handleUpdate(quarterIndex, monthIndex, occasionIndex)}>
                                                  Update
                                                </button>
                                                <button className="btn bg-green-200 text-xs" onClick={() => deleteOccasion(occasionData.id)}>
                                                  Delete
                                                </button>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </table>


                  </div>

                </div>
              ))}
            </div>



          )}
        </div>
      </PageLayout>
    </div>
  );
};

export default CalendarAdmin;
