import React, { useEffect, useState } from 'react';

import './modal.css';
import Modal from './modal';
import BannerEditCard from '../bannerEdit/BannerEditCard';
import ReactPlayer from 'react-player';
import arrowback from "../../assets/images/arrow-left.svg";
import { useSelector, useDispatch } from 'react-redux';
import { OrgRoles } from '../../helpers/userTypes';
import { S3_BASE_URL } from '../../helpers/helper';
import { getUser } from '../../redux/actions/userAction';

const WebinarDescriptionModal = ({ modalid, toggle, setOpenWebinarDescriptionModal, moreWebinars, webinar, setPlayClicked, clickFunction, activeCategoryName }) => {
    const [showFull, setShowFull] = useState(false);
    const dispatch = useDispatch()
    const [play, setPlay] = useState(false);
    const [playTrailerClicked, setPlayTrailerClicked] = useState(false);
    const [watchSessionClicked, setWatchSessionClicked] = useState(false);
    const [sectionToShow, setSectionToShow] = useState('play');
    const { userInfo } = useSelector(state => state.getUser);

    useEffect(() => {
        if (!userInfo) {
            dispatch(getUser());
        }
        const playToggler = setTimeout(() => {
            setPlay(true);
        }, 500);
        return () => {
            clearTimeout(playToggler);
        }
    }, [])
    useEffect(() => {
        if (userInfo?.data) {
            if (OrgRoles.includes(userInfo.data.role))
                setShowFull(true);
            else
                setShowFull(false);
        }
    }, [userInfo])
    const handlePlay = (flag) => {
        setSectionToShow('watch');
        setWatchSessionClicked(true)
        setPlayTrailerClicked(false);
        // setPlayClicked(flag);
        // setOpenWebinarDescriptionModal(false);
    }

    const handlePlayTrailer = (flag) => {
        setSectionToShow('play');
        setPlayTrailerClicked(true);
        setWatchSessionClicked(false)
    }
    const handleClick = (webinar) => {
        setPlay(false);
        setPlayTrailerClicked(false);

        clickFunction(webinar);
    }

    // const checkMembership = (e) => {
    //     if (!showFull) {
    //         setOpenPurchasePlansModal(true);
    //     }
    // }
    const [isReadMore, setIsReadMore] = useState(false);

    const handleToggle = () => {
        setIsReadMore(prevState => !prevState);
    };

    const truncatedDescription = webinar.description.length > 200 ? webinar.description.substring(0, 200) + "..." : webinar.description;

    return (
        <Modal modalid={modalid} toggle={toggle}>
            <div className="modal-body"  >
                <div className="close-icon" data-dismiss="modal" onClick={() => setOpenWebinarDescriptionModal(false)} aria-label="Close">
                    <div className="close-btn-icon"></div>
                </div>
                <div className="webinar-description" style={{ height: '92vh' }}>
                    <div className="back-arrow">
                        <img src={arrowback} alt="back" onClick={() => setOpenWebinarDescriptionModal(false)} />
                    </div>
                    <div className="title">
                        <h4 className="mt-0 mb-3" style={{ lineHeight: 'unset' }}>
                            <span className="category">{activeCategoryName} : </span>
                            {webinar.title}
                        </h4>
                    </div>
                    {sectionToShow == 'play' && (
                        <div className={`rounded-[10px] preview ${playTrailerClicked ? "" : "bg-light"}`}>
                            {
                                playTrailerClicked && webinar?.teaserUrl && webinar.teaserUrl.includes('https://youtu.be') ?
                                    <ReactPlayer
                                        url={webinar.teaserUrl}
                                        onPause={() => setPlay(false)}
                                        onPlay={() => setPlay(true)}
                                        loop={true}
                                        height={'220px'}
                                        width={'100%'}
                                        playing={play}
                                        controls
                                        config={{
                                            youtube: {
                                                playerVars: { disablekb: 1, origin: 'https://www.youtube.com' }
                                            }
                                        }} />
                                    : (
                                        <>
                                            <img
                                                className='md:block hidden !mt-0 preview-image hide769'
                                                src={S3_BASE_URL + webinar.coverMedia}
                                                alt="Webinar cover"
                                            />
                                            <img
                                                className='block md:hidden !mt-0 preview-image show769'
                                                src={S3_BASE_URL + webinar.mobileCoverMedia}
                                                alt="Webinar mobile cover"
                                            />
                                        </>

                                    )
                                // : webinar?.coverMedia && /\.(gif|jpg|jpeg|png)$/.test(webinar.coverMedia) ? (
                                //     <img className='!mt-0 preview-image hide769' src={S3_BASE_URL + webinar.coverMedia} />

                                // )
                                //  : webinar?.mobileCoverMedia && /\.(gif|jpg|jpeg|png)$/.test(webinar.mobileCoverMedia) ? (
                                //     <img className='!mt-0 preview-image show769' src={S3_BASE_URL + webinar.mobileCoverMedia} />

                                // )
                                //  : (
                                //     <>
                                //         <div className="bg-slate-100 rounded-[10px] w-full !h-full show769 skeleton-animation"></div>
                                //         <div className="bg-slate-100 rounded-[10px] w-full !h-[220px] hide769 skeleton-animation"></div>

                                //     </>
                                // )

                            }


                        </div>
                    )}


                    {sectionToShow == 'watch' && (<div className={`rounded-[10px] preview ${watchSessionClicked ? "" : "bg-light"}`}>
                        {watchSessionClicked &&
                            <ReactPlayer
                                url={showFull ? webinar.fullVideoUrl : webinar.previewUrl}
                                onPause={() => setPlay(false)}
                                onPlay={() => setPlay(true)}
                                loop={true}
                                height={'220px'}
                                width={'100%'}
                                playing={play}
                                controls
                                config={{
                                    youtube: {
                                        playerVars: { disablekb: 1, origin: 'https://www.youtube.com' }
                                    }
                                }} />


                        }
                    </div>)}

                    <div className="btn-group">
                        <button className='btn btn-primary' onClick={() => handlePlayTrailer()}>Play Trailer</button>
                        <button className={`btn btn-primary ${userInfo?.data?.email ? "" : "!cursor-not-allowed	"}`} disabled={!userInfo?.data?.email} onClick={() => handlePlay("SESSION")}>Watch the session</button>
                    </div>
                    <div className="description">
                        <h4 className='mt-4 sm:mt-0 mb-0 sm:mb-4 title'>About the session</h4>
                        <p className='mb-0'>
                            {isReadMore ? webinar.description : truncatedDescription}
                            <span
                                className='text-blue-500 cursor-pointer'
                                onClick={handleToggle}>
                                {isReadMore ? ' Read Less' : ' Read More'}
                            </span>
                        </p>
                    </div>
                    {
                        moreWebinars.length > 0 &&
                        <div className="more-like-this">
                            <h4 className="mt-4 sm:mt-0 mb-0 sm:mb-4 title">More like this</h4>
                            <div className="more-webinars" style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                flexDirection: 'row'
                            }}>
                                {
                                    moreWebinars.map(webinar =>
                                        <BannerEditCard key={JSON.stringify(webinar) + "on-modal"} image={webinar.mobileCoverMedia} webinar={webinar} clickFunction={handleClick} fromWebinarDescr={true} />
                                    )
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Modal>
    );
};
export default WebinarDescriptionModal;